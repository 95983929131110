exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/Contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/Footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-head-js": () => import("./../../../src/pages/Head.js" /* webpackChunkName: "component---src-pages-head-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-hero-js": () => import("./../../../src/pages/Hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/OurServices.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-ourwork-js": () => import("./../../../src/pages/Ourwork.js" /* webpackChunkName: "component---src-pages-ourwork-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/Testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

